.avatar {
    border-radius: 100%;
    background-color: var(--avatar-bg);
    color: var(--avatar-text-color);
    text-transform: uppercase;

    display: flex;
    align-items: center;
    justify-content: center;

    &.xsmall {
        flex: 0 0 1.5rem;
        width: 1.5rem;
        height: 1.5rem;
        font-weight: 500;
        font-size: 0.725rem;
    }
    &.small {
        flex: 0 0 2.5rem;
        width: 2.5rem;
        height: 2.5rem;
        font-weight: 500;
        font-size: 1.25rem;
    }

    &.medium {
        flex: 0 0 5rem;
        width: 5rem;
        height: 5rem;
        font-weight: 600;
        font-size: 2.5rem;
    }
}
