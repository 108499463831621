@import "~/styles/responsive.scss";

.homepage {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  
  @include screen(lg) {
    gap: 2rem;
  }
}
