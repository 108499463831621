@import "~/styles/responsive.scss";

.dk_btn {
  --btn-height: 2.5rem;
  --btn-font-size: 0.875rem;
  --btn-bg-color: var(--primary);
  --btn-hover-bg-color: var(--primary-light);
  --btn-color: var(--grey-0);
  --btn-hover-color: var(--grey-0);
  --btn-border-color: var(--primary);

  padding: 0 1rem;
  border-radius: var(--btn-border-radius);
  height: var(--btn-height);
  background-color: var(--btn-bg-color);
  color: var(--btn-color);
  border: 1px solid var(--btn-border-color);
  font-size: var(--btn-font-size);
  font-weight: 500;
  transition: all 0.25s ease-in-out;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  text-transform: inherit;


  @include screen(lg) {
    &:hover {
      background-color: var(--btn-hover-bg-color);
      color: var(--btn-hover-color);
      * {
        color: var(--btn-hover-color) !important;
      }
      svg{
          fill: var(--btn-hover-color) !important;
      }
  
      &.btn_quaternary {
        background-color: var(--primary);
        color: var(--grey-0);
      }
    }
  }

  &.btn_solid {
    &.btn_secondary {
      --btn-bg-color: #0f0f0f;
      --btn-border-color: #0f0f0f;
      --btn-color: white;
      --btn-hover-bg-color: #0f0f0f;
    }

    &.btn_quaternary {
      --btn-bg-color: var(--primary);
      --btn-border-color: var(--primary);
      --btn-color: var(--grey-0);
      --btn-hover-bg-color: var(--grey-0);
    }
  }

  &.btn_outline {
    --btn-bg-color: transparent;
    --btn-color: var(--primary);
    --btn-hover-color: var(--grey-0);
    --btn-border-color: var(--primary);

    &.btn_secondary {
      --btn-color: #0f0f0f;
      --btn-border-color: #0f0f0f;
      --btn-hover-bg-color: #0f0f0f;
      --btn-hover-color: var(--grey-0);
    }

    &.btn_tertiary {
      --btn-color: white;
      --btn-hover-color: #0f0f0f;
      --btn-border-color: white;
      --btn-hover-bg-color: white;
    }

    &.btn_quaternary {
      --btn-color: var(--primary);
      --btn-border-color: var(--primary);
      --btn-hover-bg-color: var(--primary);
      --btn-hover-color: var(--grey-0);
    }
  }

  &.btn_borderless {
    --btn-bg-color: transparent;
    --btn-color: var(--primary);
    --btn-hover-color: var(--primary);
    --btn-border-color: transparent;
    --btn-hover-bg-color: var(--grey-50);

    &.btn_secondary {
      --btn-color: var(--grey-700);
      --btn-hover-color: var(--grey-700);
    }

    &.btn_tertiary {
      --btn-color: var(--grey-0);
    }
  }
  &.loading,
  &.disabled,
  &.disabled:hover,
  &.disabled:focus,
  &.disabled:active {
    cursor: not-allowed;
    pointer-events: none;
    --btn-color: var(--grey-300);
    --btn-hover-color: var(--grey-300);

    &.btn_solid {
      --btn-bg-color: var(--grey-50);
      --btn-border-color: var(--grey-50);
      --btn-hover-bg-color: var(--grey-50);
    }

    &.btn_outline {
      --btn-border-color: var(--grey-50);
      --btn-hover-bg-color: transparent;
    }

    &.btn_borderless {
      --btn-hover-bg-color: transparent;
    }
  }

  &.btn_large {
    --btn-height: 3rem;
    --btn-font-size: 1rem;
  }

  &.btn_small {
    --btn-height: 2rem;
  }

  &.btn_square {
    aspect-ratio: 1;
    padding: unset;
    justify-content: center;
  }
}
