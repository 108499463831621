@import "~/styles/responsive.scss";

.search_filter {
  position: absolute;
  width: 100%;
  box-shadow: 0px 0.25rem 0.25rem 0px rgba(0, 0, 0, 0.05);
  padding: .5rem 1rem 1rem;
  left: 0;
  background-color: white;
  top: 100%;

  display: flex;
  flex-direction: column;
  gap: .5rem;
  overflow-y: auto;
  z-index: 0;
  max-height: 75vh;
  border-end-start-radius: 15px ;
  border-end-end-radius: 15px ;

  @include screen(lg) {
    padding: 1rem 1.5rem 1.5rem;
    gap: .5rem;
  }

  .item {
    display: flex;
    flex-direction: column;
    gap: .5rem;

    .tags {
      display: flex;
      flex-wrap: wrap;
      gap: 0.5rem;
    }

    .products {
      display: grid;
      gap: 1.25rem;

      @include screen(sm) {
        grid-template-columns: repeat(2, 1fr);
      }

      @include screen(lg) {
        grid-template-columns: repeat(4, 1fr);
      }

      .product_item {
        aspect-ratio: 1 / 1.25;
        background-color: var(--grey-50);
      }
    }
  }

  .view_all {
    color: var(--primary);
    text-align: center;
  }
}
