@import '~/styles/responsive.scss';

.text {
    &.headline {
        &.headline_1 {
            font-weight: 700;
            font-size: 2rem;

            @include screen(lg) {
                font-size: 2.5rem; // 40px
            }
        }

        &.headline_2 {
            font-weight: 700;
            font-size: 1.75rem;

            @include screen(lg) {
                font-size: 3.5rem; // 56px
            }
        }

        &.headline_3 {
            font-size: 1.5rem;
            font-weight: 700;

            @include screen(lg) {
                font-size: 2rem; // 32px
            }
        }

        &.headline_4 {
            font-size: 1.25rem;
            font-weight: 700;

            @include screen(lg) {
                font-size: 1.75rem; // 28px
            }
        }
    }

    &.title {
        font-weight: bold;

        &.title_1 {
            font-size: 1.125rem;

            @include screen(lg) {
                font-size: 1.5rem; // 24px
            }
        }
        &.title_2 {
            font-size: 1rem;

            @include screen(lg) {
                font-size: 1.25rem; // 20px
            }
        }
        &.title_3 {
            font-size: 1rem;

            @include screen(lg) {
                font-size: 1rem; // 16px
            }
        }
        &.title_4 {
            font-size: 0.875rem;

            @include screen(lg) {
                font-size: 1rem; // 16px
            }
        }
        &.title_5 {
            font-size: 0.875rem; // 14px
        }
    }

    &.label {
        font-weight: 500;

        &.label_1 {
            font-size: 1rem;

            @include screen(lg) {
                font-size: 1.5rem; // 24px
            }
        }
        &.label_2 {
            font-size: 0.875rem;

            @include screen(lg) {
                font-size: 1.25rem; // 20px
            }
        }
        &.label_3 {
            font-size: 0.875rem; // 14px

            @include screen(lg) {
                font-size: 1rem; // 16px
            }
        }
        &.label_4 {
            font-size: 0.75rem;

            @include screen(lg) {
                font-size: 0.875rem; // 14px
            }
        }
        &.label_5 {
            font-size: 0.75rem; // 12px
        }
    }

    &.body {
        &.body_1 {
            font-size: 1rem;
        }
        &.body_2 {
            font-size: 0.75rem; // 16px
            @include screen(lg) {
                font-size: 0.875rem; // 14px
            }
        }
        &.body_3 {
            font-size: 0.75rem;

            @include screen(lg) {
                font-size: 0.875rem; // 14px
            }
        }
        &.body_4 {
            font-size: 0.75rem; // 12px
        }
    }
}
