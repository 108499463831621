@import '~/styles/responsive.scss';

// .navigation {
//   background-color: var(--navigation-bg);
//   position: sticky;
//   top: 0;
//   z-index: 999;
//   height: 0px;
//   width: 100%;

//   @include screen(lg) {
//     min-height: 3rem;
//     height: unset;
//   }
// }

.header {
    width: 100%;
    background-color: var(--header-bg);
    border-bottom: 1px solid var(--header-border-color);
    position: sticky;
    top: 0;
    z-index: 999;
    min-height: 48px;
    & svg {
        color: var(--header-color);
    }
 

}

.mobile_header {
    width: 100vw !important;
    padding: 1rem;
    display: flex;
    flex-direction: column;

    * {
        color: var(--grey-900);
    }
}

.wish_bage {
    background-color: var(--primary);
    color: white;
    position: absolute;
    top: 0;
    right: 0;
    min-width: 1rem;
    aspect-ratio: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 0.75rem;
    border-radius: 100%;
}
