@import '~/styles/responsive.scss';

.sub_mobile_header {
    width: 100vw !important;
    display: flex;
    flex-direction: column;
    top: 58px !important;
    box-shadow: unset !important;
    border-left: 1px solid var(--grey-200);
    height: unset !important;
}

.sub_item_mobile_header {
    width: 100vw !important;
    display: flex;
    flex-direction: column;
    box-shadow: unset !important;
    border-left: 1px solid #d9d9d9;
    height: calc(100vh - 58px) !important;
}

.sub_drawer_overlay {
    background-color: unset !important;
    top: 58px !important;
}

.sub_item_drawer_overlay {
    background-color: unset !important;
    top: 0px !important;
}
