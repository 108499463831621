.divider {
  display: flex;
  width: 100%;
  gap: 1.25rem;
  align-items: center;

  &.topLeft,
  &.bottomLeft {
    align-items: start;
  }

  &.topRight,
  &.bottomRight {
    align-items: end;
  }
}
