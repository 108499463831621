
.input_container {
  --input-height: calc(2.5rem - 2px);

  display: flex;
  flex-direction: column;
  gap: 0.25rem;

  .input_label {
    font-size: 0.8125rem;
    font-weight: 600;
    line-height: 138%;
    color: var(--grey-700);

    &.required {
      &::after {
        content: " *";
        color: var(--accent-red);
      }
    }
  }

  &.border-bottom {
    .input_field {
      border: none;
      border-bottom: 1px solid var(--grey-100);
      border-radius: 0;
    }
  }

  &.transparent {
    .input_field {
      background-color: transparent;

      .input {
        background-color: transparent;
      }
    }
  }

  &.disabled {
    .input_field {
      cursor: not-allowed;
      background-color: var(--grey-50);
      border-color: var(--grey-50);
      color: var(--grey-300);

      &:focus-within,
      &:hover {
        border-color: var(--grey-50);
      }

      .input {
        &:disabled {
          cursor: not-allowed;
        }
      }
    }
  }

  .input_field {
    position: relative;
    background-color: white;
    border-radius: 0.5rem;
    border: 1px solid var(--input-border-color);
    display: flex;
    align-items: center;
    transition: all 0.25s ease-in-out;
    overflow: hidden;

    &:focus-within,
    &:hover {
      border-color: var(--primary);
    }

    &.has_error {
      border-color: var(--accent-red);
    }

    .prefix {
      padding-left: 0.75rem;
      font-size: 0.875rem;
      color: var(--grey-400);
      font-weight: 600;
    }

    .input {
      width: 100%;
      height: var(--input-height);
      padding: 0 0.75rem;
      outline: none;
      font-size: 0.875rem;
      line-height: 1.0625rem;
      transition: all 0.25s ease-in-out;
      border-radius: 0.5rem;

      &::placeholder {
        color: var(--grey-300);
      }
 
    }

    .clear_icon {
      cursor: pointer;
      margin-right: 0.5rem;
    }

    .suffix_icon {
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 0 1rem 0 0.25rem;
    }

    .counter {
      color: var(--grey-300);
    }
  }

  .hint {
    font-size: 0.75rem;
    color: var(--grey-300);
  }

  .error {
    font-size: 0.75rem;
    color: var(--accent-red);
  }

  .country_select {
    z-index: 1;
    position: relative;

    &:after {
      content: "";
      position: absolute;
      height: 1.25rem;
      width: 1px;
      right: 0;
      background-color: var(--input-border-color);
      top: 50%;
      transform: translateY(-50%);
    }

    button {
      border: none !important;
    }

    ul {
      min-width: max-content;
      max-width: 500px;
    }
  }
}
