@import '~/styles/responsive.scss';

.header {
    width: 100%;
    background-color: var(--header-bg);
    border-bottom: 1px solid var(--header-border-color);
    position: sticky;
    top: 0;
    z-index: 999;
}

.mobile_header {
    width: 100vw !important;
    padding: 1rem 0px;
    display: flex;
    flex-direction: column;

    .drawer_title {
        border-bottom: 1px solid #d3d3d3;
        padding-bottom: 8px;
    }
}

.wish_bage {
    background-color: var(--primary);
    color: white;
    position: absolute;
    top: 0;
    right: 0;
    min-width: 1rem;
    aspect-ratio: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 0.75rem;
    border-radius: 100%;
}

.drawer_icon {
    background-color: #21232614;
    border-radius: 50%;
}
