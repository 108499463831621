.loadingPage{
    position: fixed;
    z-index: 10000;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transform: translate3d(0, 0, 0);
    // background-color: black;
    background-color: rgba(0, 0, 0, 0.3);
    background: radial-gradient(closest-corner ellipse at center, rgba(0, 0, 0, 0.4) 1%, rgba(0, 0, 0, 0.6) 100%);
    color: #fff;
    .loadingContent{
      position: absolute;
      top: 50%;
      left: 50%;
      text-align: center;
      transform: translate(-50% , -50%);
      background-color: black;
      background-color: rgba(0, 0, 0, 0.8);
      padding: 15px;
      border-radius: 5px;
      // border: 1px solid var(--primary);
      box-shadow: 0 0 6px 0 var(--primary);
      // color: var(--primary);
    }
    svg {
      color: #fff;
    }
  }
  